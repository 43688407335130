import './App.css';
import Navbar from "./components/Navbar.js";
import React from 'react';
import CardTable from "./components/CardTable.js";
import CourseDetails from './components/CourseDetails';
import Course from './components/Course.js';
import Popup from './components/Popup.js';
import Auth0Callback from './components/Auth0Callback.js';
import CentralLogout from './components/CentralLogout.js';

import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { AuthContextProvider, useAuth } from './context/authContext';
import { CompletionProvider } from './context/completionContext';


function App() {
  const { userId } = useAuth();

  return (
    <CompletionProvider>
      <Router>
        <div>
          <Navbar />
          {userId && <Popup userId={userId} />} {/* Show Popup only if userId exists */}
          <Routes>
            <Route path="/" element={<CardTable />}/>
            <Route path="/callback" element={<Auth0Callback />} />
            <Route path="/logout" element={<CentralLogout/>} />
            <Route path="/course_details/:id" element={<CourseDetails />}/>
            <Route path="/course/:courseId" element={<RequireAuth />}>
              <Route index element={<Course />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </CompletionProvider>
  );
}

const RequireAuth = () => {
  const { isLoggedIn } = useAuth();
  return isLoggedIn ? <Outlet /> : <Navigate to="/" replace />;
};

export default App;
