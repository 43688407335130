import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../utils/api';

const CentralLogout = () => {
  const navigate = useNavigate();
  const hasLoggedOut = useRef(false); 


  useEffect(() => {

    const performLogout = async () => {
      try {
        // Ensure the API URL is correctly set in environment variables
        const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5002'; // Adjust as needed

        // Make the POST request to central-logout
        const response = await api.post(`${apiUrl}/central-logout`, {}, {
          withCredentials: true,
        });

        // Extract the logoutUrl from the response
        const { logoutUrl } = response.data;

        if (logoutUrl) {
          // Redirect the browser to the Auth0 logout URL
          window.location.href = logoutUrl;
        } else {
          // If no logoutUrl is provided, navigate to a fallback page
          console.error('No logout URL received from server.');
          navigate('/');
        }
      } catch (error) {
        console.error('Central logout failed:', error);
        // Navigate to the welcome page even if logout fails
        navigate('/welcome');
      }
    };

    performLogout();
  }, [navigate]);

  console.log("in central logout component");
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      <h1 className="text-2xl text-gray-700">Logging you out from all applications...</h1>
      <p className="text-lg text-gray-500 mt-4">Please wait while we securely log you out.</p>
    </div>
  );
//     if (hasLoggedOut.current) return;
//     hasLoggedOut.current = true;

//     const logoutUrls = [
//       'http://localhost:3001/logout',
//       'http://localhost:3000/logout',
//       // 'https://test-learn-alpha.vercel.app/logout',
//       // 'https://gemi-landingpage-three.vercel.app/logout', 
//       // 'https://learn.gemi-ai.tech/logout',
//       // 'https://hub.gemi-ai.tech/logout'
//     ];

//     let loadedCount = 0;
//     let failedCount = 0;

//     const handleIframeLoad = () => {
//       loadedCount += 1;
//       if (loadedCount === logoutUrls.length) {
//         navigate('/');
//       }
//     };
//     const handleIframeError = (url) => {
//       failedCount += 1;
//       console.error(`Failed to load logout iframe: ${url}`);
//       if (loadedCount + failedCount === logoutUrls.length) {
//         console.log('All logout attempts completed with some failures.');
//         navigate('/welcome');
//       }
//     };

//     logoutUrls.forEach((url) => {
//       const iframe = document.createElement('iframe');
//       iframe.style.display = 'none';
//       iframe.src = url;
//       iframe.onload = () => {
//         handleIframeLoad();
//         document.body.removeChild(iframe);
//       };
//       iframe.onerror = () => {
//         handleIframeError(url);
//         document.body.removeChild(iframe);
//       };
//       document.body.appendChild(iframe);
//     });
//   }, [navigate]);

//     console.log("in central logout component")
// //   return <div>Logging you out from all applications...</div>;
//     return null;
};

export default CentralLogout;
