import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import logo from "./images/logo.jpg"
import defaultuser from "./images/defaultuser.jpg"
import { BiCaretDown } from "react-icons/bi";
import { useAuth } from '../context/authContext';
import { useAuth0 } from '@auth0/auth0-react';


const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);  // State to control dropdown visibility
  const navigate = useNavigate(); 
  const { logout } = useAuth();
  const { isAuthenticated, user } = useAuth0();
  const profilePic = user?.picture || defaultuser;

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // const handleLogout = () => {
  //   logout({ returnTo: window.location.origin });
  // };
  const handleLogout = async () => {
    console.log("clicked on logout")
    await logout();
  }

    return (
      <nav className="navbar flex justify-between items-center p-5">
        <div className="flex items-center space-x-2 "> 
          <Link className="nav-logo" to="/">
            <img className="logo-img" src={logo} alt="Logo" />
          </Link>
          <h1 className="user-name ml-2"></h1>
        </div>

    {/* User Dropdown */}
    {isAuthenticated && (
      <div className="relative items-center ml-[900px]"> 
        <button
          className="user-dropdown flex items-center border border-gray-300 rounded p-2 pr-[100px]"
          onClick={toggleDropdown}  // Toggle dropdown on button click
        >
          <div className="user-img">
            <img src={profilePic} alt="User" className="h-8 w-8 rounded-full" /> 
          </div>
          <h6 className="user-name ">{user.name || 'User'}</h6>
          <BiCaretDown className="" />
        </button>

        {/* Dropdown Menu */}
        {dropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg z-10">
            <li className="block px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={handleLogout}>Logout</li>
          </div>
        )}
      </div>
    )}
  </nav>
);
};

export default Navbar;