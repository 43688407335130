import { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {createAuth0Client} from '@auth0/auth0-spa-js';
import api from '../utils/api';


// Create the AuthContext
export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState(null);
  const [checkedLogs, setCheckedLogs] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { isLoading, isAuthenticated, user, loginWithRedirect, logout, getAccessTokenSilently, } = useAuth0();
  const [isRedirecting, setIsRedirecting] = useState(false);



  const checkAuthStatus = async () => {
    console.log("[checkAuthStatus] isLoading:", isLoading, " isAuthenticated:", isAuthenticated);

    if (isLoading){
      console.log("Auth0 is still loading...")
      return;
    }

    if (!isAuthenticated) {
      console.warn("User is not authenticated. Skipping token retrieval.");
      setIsRedirecting(true);
      await loginWithRedirect({
        scope: 'openid profile email offline_access',
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      })
      setCheckedLogs(true);
      return;
    }
    else{
      if (isAuthenticated){
        try {
          const token = await getAccessTokenSilently
          ({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "openid profile email offline_access",
          });
          console.log("here is the token:", token)

          const response = await api.post('/auth/sync', {
            email: user.email,
            name: user.name,
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          });

          if (response.data.user) {
            setUserId(response.data.user.id);
            setUserName(response.data.user.name);
            setIsLoggedIn(true);
          } else {
            setIsLoggedIn(false);
          }

          setCheckedLogs(true);
        } catch (error) {
          console.error('Failed to sync user with backend:', error);
          setIsLoggedIn(false);
          setUserId(null);
          setUserName('');
          setCheckedLogs(true);
        }
      }
    }
  }

  useEffect(() => {
    checkAuthStatus();
  }, [isLoading, isAuthenticated, user]);

  useEffect(() => {
    if (isAuthenticated) {
      console.log("Authenticated user:", user);
    } else {
      console.warn("Not authenticated");
    }
  }, [isAuthenticated, user]);

  // Logout and clear backend session
  const handleLogout = async () => {
    try {
      logout({
        returnTo: window.location.origin,
        federated: true,
      });
        window.location.href = 'https://hub.gemi-ai.tech/logout';
    } catch (error) {
      console.error('Logout failed:', error);
    }
    // try {
    //   logout();
    //   const response = await api.post('/logout', {}, { withCredentials: true });  // Clear backend session
    //   if (response.data.logoutUrl) { // Redirect to Auth0 logout URL
    //     window.location.href = response.data.logoutUrl;
    //   }
    // } catch (error) {
    //   console.error('Logout failed:', error);
    // }
  };
  // const handleLogout = async () => {
  //   try {
  //     // Call your backend's central logout endpoint
  //     await api.post('/central-logout', {}, { withCredentials: true });
  //   } catch (error) {
  //     console.error('Central logout failed:', error);
  //   }
    // try {
    //   console.log("initiating auth0 logout...")
    //   // Initiate Auth0 logout
    //   await logout({
    //     returnTo: 'http://localhost:3001/logout', // Central logout handler
    //     // returnTo: 'https://test-learn-alpha.vercel.app/logout',
    //     client_id: process.env.REACT_APP_AUTH0_CLIENT_ID, 
    //   });
    //   console.log("after auth0 logout")
    //   // Optionally, clear backend session by calling the centralized logout endpoint
    //   const response = await api.post('/logout', {}, { withCredentials: true });
    //   if (response.data.logoutUrl) {
    //     window.location.href = response.data.logoutUrl;
    //   }
    // } catch (error) {
    //   console.error('Logout failed:', error);
    // }
  // };

  // // Define checkAuthStatus inside the component
  // const checkAuthStatus = async () => {
  //   try {
  //     const response = await api.get('/auth/verify', { withCredentials: true });
  //     if (response.data.isLoggedIn) {
  //       setIsLoggedIn(true);
  //       setUserName(response.data.user.name);
  //       setUserId(response.data.user.id);
  //     } else {
  //       setIsLoggedIn(false);
  //       setUserName('');
  //       setUserId(null);
  //     }
  //   } catch (error) {
  //     console.error('Authentication check failed:', error);
  //     setIsLoggedIn(false);
  //     setUserName('');
  //     setUserId(null);
  //   }
  // };

  // // Check login status on component mount and when location changes
  // useEffect(() => {
  //   checkAuthStatus();
  // }, [isLoggedIn]);

  // const logout = async () => {
  //   try {
  //     await api.post('/logout', {}, { withCredentials: true });
  //     await checkAuthStatus();
  //     window.location.href = 'https://hub.gemi-ai.tech/welcome';
  //   } catch (error) {
  //     console.error('Logout failed:', error);
  //   }
  // };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isAuthenticated,
        // login: loginWithRedirect,
        login: async () => await loginWithRedirect({
          scope: 'openid profile email offline_access',
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        }),
        logout: handleLogout,
        userName,
        userId,
        user,
        checkedLogs,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Hook to use AuthContext in other components
export const useAuth = () => useContext(AuthContext);