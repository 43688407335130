import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const Auth0Callback = () => {
  const { isAuthenticated, isLoading, error, user, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) return; 
    if (error) {
      console.error(error);
      return;
    }
    if (!isAuthenticated) {
      loginWithRedirect();
    } else {
      navigate('/');
    }
  }, [isLoading, isAuthenticated, error, loginWithRedirect, navigate]);

  return <div>Loading...</div>;
};

export default Auth0Callback;
